import $ from 'jquery';

// パララックス
$(function(){
  const parallaxTarget = document.querySelector('.js-parallax-target');
  if(parallaxTarget !== null) {

    const mv_left = $('.js-parallax-mv-left');
    const mv_left_target = mv_left.offset().top - 600;
  
    const form_right = $('.js-parallax-form-right');
    const form_right_target = form_right.offset().top - 600;
  
    const news_left = $('.js-parallax-news-left');
    const news_left_target = news_left.offset().top - 950;
  
    const rooms_right = $('.js-parallax-rooms-right');
    const rooms_right_target = rooms_right.offset().top - 1480;
  
    const breakfast_left = $('.js-parallax-breakfast-left');
    const breakfast_left_target = breakfast_left.offset().top - 2600;
  
    const breakfast_right = $('.js-parallax-breakfast-right');
    const breakfast_right_target = breakfast_right.offset().top - 1600;
  
    const facilities_left = $('.js-parallax-facilities-left');
    const facilities_left_target = facilities_left.offset().top - 200;
  
    const facilities_right = $('.js-parallax-facilities-right');
    const facilities_right_target = facilities_right.offset().top - 900;
  
    const sightseeing_left = $('.js-parallax-sightseeing-left');
    const sightseeing_left_target = sightseeing_left.offset().top - 4000;
  
    const access_right = $('.js-parallax-access-right');
    const access_right_target = access_right.offset().top - 1600;
  
    const banner_left = $('.js-parallax-banner-left');
    const banner_left_target = banner_left.offset().top - 1385;
  
    const targetFactor = 1;
  
    const windowH = $(window).height();
    const mv_left_scrollY = mv_left_target - windowH;
    const form_right_scrollY = form_right_target - windowH;
    const news_left_scrollY = news_left_target - windowH;
    const rooms_right_scrollY = rooms_right_target - windowH;
    const breakfast_left_scrollY = breakfast_left_target - windowH;
    const breakfast_right_scrollY = breakfast_right_target - windowH;
    const facilities_left_scrollY = facilities_left_target - windowH;
    const facilities_right_scrollY = facilities_right_target - windowH;
    const sightseeing_left_scrollY = sightseeing_left_target - windowH;
    const access_right_scrollY = access_right_target - windowH;
    const banner_left_scrollY = banner_left_target - windowH;

    if (window.matchMedia( '(min-width: 767px)' ).matches) {
      $(window).on('scroll',function(){
        const scrollY = $(this).scrollTop();
        if(scrollY > mv_left_scrollY){
          mv_left.css('bottom', (scrollY - mv_left_target) * targetFactor + 'px');
        }else{
          mv_left.css('bottom','0');
        }
        if(scrollY > form_right_scrollY){
          form_right.css('bottom', (scrollY - form_right_target) * targetFactor + 'px');
        }else{
          form_right.css('bottom','0');
        }
        if(scrollY > news_left_scrollY){
          news_left.css('bottom', (scrollY - news_left_target) * targetFactor + 'px');
        }else{
          news_left.css('bottom','0');
        }
        if(scrollY > rooms_right_scrollY){
          rooms_right.css('bottom', (scrollY - rooms_right_target) * targetFactor + 'px');
        }else{
          rooms_right.css('bottom','0');
        }
        if(scrollY > breakfast_left_scrollY){
          breakfast_left.css('bottom', (scrollY - breakfast_left_target) * targetFactor + 'px');
        }else{
          breakfast_left.css('bottom','0');
        }
        if(scrollY > breakfast_right_scrollY){
          breakfast_right.css('bottom', (scrollY - breakfast_right_target) * targetFactor + 'px');
        }else{
          breakfast_right.css('bottom','0');
        }
        if(scrollY > facilities_left_scrollY){
          facilities_left.css('bottom', (scrollY - facilities_left_target) * targetFactor + 'px');
        }else{
          facilities_left.css('bottom','0');
        }
        if(scrollY > facilities_right_scrollY){
          facilities_right.css('bottom', (scrollY - facilities_right_target) * targetFactor + 'px');
        }else{
          facilities_right.css('bottom','0');
        }
        if(scrollY > sightseeing_left_scrollY){
          sightseeing_left.css('bottom', (scrollY - sightseeing_left_target) * targetFactor + 'px');
        }else{
          sightseeing_left.css('bottom','0');
        }
        if(scrollY > access_right_scrollY){
          access_right.css('bottom', (scrollY - access_right_target) * targetFactor + 'px');
        }else{
          access_right.css('bottom','0');
        }
        if(scrollY > banner_left_scrollY){
          banner_left.css('bottom', (scrollY - banner_left_target) * targetFactor + 'px');
        }else{
          banner_left.css('bottom','0');
        }
      });
    }
  }
});