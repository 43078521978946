import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';

Swiper.use([Autoplay,Navigation,Pagination,EffectFade]);

// TOPページとgolobalページのMVスライダー
new Swiper('.p-mv__slide-wrap', {
  loop: false,
  slidesPerView: 1,
  loop: true,
  speed: 1500,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: '.p-mv__swiper-pagination',
    clickable: true,
    type:"bullets",
  }
})

// TOPページ newsスライダー

const newsSlidePc = () => {
  if (window.matchMedia('(min-width: 1024px)').matches) {
    let newsSlideLength = document.querySelectorAll('.p-top-news__slide .swiper-slide').length;
    if (newsSlideLength < 4) {
      
      new Swiper('.p-top-news__slide-wrap', {
        init: true,
        loop: false,
        autoplay: false,
        watchOverflow: true,
        slidesPerView: 3,
        spaceBetween: 50,
        navigation: {
          nextEl: '.p-top-news__swiper-button-next',
          prevEl: '.p-top-news__swiper-button-prev',
        },
      });
    } else {
      new Swiper('.p-top-news__slide-wrap', {
        watchOverflow: true,
        loop: true,
        autoplay: true,
        slidesPerView: 3,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.p-top-news__swiper-button-next',
          prevEl: '.p-top-news__swiper-button-prev',
        },
        autoplay: {
          delay: 3000,
        },
      });
    }
  }
};

const newsSlideTablet = () => {
  if(window.matchMedia('(min-width: 640px) and (max-width: 1023px)').matches) {
    new Swiper('.p-top-news__slide-wrap', {
      watchOverflow: true,
      loop: true,
      autoplay: true,
      slidesPerView: 2,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.p-top-news__swiper-button-next',
        prevEl: '.p-top-news__swiper-button-prev',
      },
      autoplay: {
        delay: 3000,
      },
    });
  }
};
const newsSlideSp = () => {
  if(window.matchMedia('(max-width: 639px)').matches) {
    new Swiper('.p-top-news__slide-wrap', {
      watchOverflow: true,
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.p-top-news__swiper-button-next',
        prevEl: '.p-top-news__swiper-button-prev',
      },
      autoplay: {
        delay: 3000,
      }
    });
  }
};
const mediaQueryPc = window.matchMedia('(min-width: 1024px)');
const mediaQueryTablet = window.matchMedia('(min-width: 640px) and (max-width: 1023px)');
const mediaQuerySp = window.matchMedia('(max-width: 639px)');

mediaQueryPc.addEventListener('change', newsSlidePc);
newsSlidePc();
mediaQueryTablet.addEventListener('change', newsSlideTablet);
newsSlideTablet();
mediaQuerySp.addEventListener('change', newsSlideSp);
newsSlideSp();

// TOPページ facilityスライダー
new Swiper('.p-top-facilities__slide-wrap', {
  loop: true,
  slidesPerView: 2.2,
  spaceBetween: 10,
  pagination: {
    el: '.p-top-facilities__swiper-pagination',
    clickable: true,
    type:"bullets",
  },
  navigation: {
    nextEl: '.p-top-facilities__swiper-button-next',
    prevEl: '.p-top-facilities__swiper-button-prev',
  },
  autoplay: {
    delay: 3000,
  },
  breakpoints: {
    1024: {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 20,
    },
    767: {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 10,
    }
  }
});

