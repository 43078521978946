
let letterTargets = [...document.querySelectorAll('.js-accent-letter')];
if (letterTargets.length >= 1) {
  letterTargets.forEach((target) => {
    let letter = target.innerHTML;
    let lastLetter = letter.charAt(letter.length - 1);
    let lastLetterAccent = `<span style="color: #CFAC6B">${lastLetter}</span>`;
    let lastLetterSlice = target.innerHTML.slice( 0, -1 );
    target.innerHTML = lastLetterSlice + lastLetterAccent;
  });
}


