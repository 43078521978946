function matchHeight() {
  let elements = document.querySelectorAll('.js-height');
  let elemMaxHeight = 0;
  let elemArray = new Array;
  Array.prototype.forEach.call(elements, function(elem) {
    elem.style.height = '';
    elemArray.push(elem.clientHeight);
  });
  elemMaxHeight = Math.max.apply(null, elemArray);
  Array.prototype.forEach.call(elements, function(elem) {
    elem.style.height = elemMaxHeight + 'px';
  });
}

document.addEventListener('DOMContentLoaded', function(){
  matchHeight();
});
window.addEventListener('resize', function(){
  matchHeight();
});
