const header = document.querySelector('.js-header');
const spMenu = document.querySelector('.js-sp-menu');
// const headerDorpdownTarget = document.querySelector('.js-header-dorpdown-target');
// const headerDorpdown = document.querySelector('.js-header-dorpdown');
const headerHamburgerMenu = document.querySelector('.js-hamburger');
const mvHeightTarget = document.querySelector('.js-mv-height-target');
const mvHeight = mvHeightTarget.clientHeight;
const html = document.querySelector('html');
const body = document.querySelector('body');


function headerScroll() {
  const scrollY = window.pageYOffset;
  if (mvHeight < scrollY) {
    header.classList.add('is-fixed');
  } else {
    header.classList.remove('is-fixed');
  }
}

window.addEventListener('scroll', headerScroll);
window.addEventListener('load', headerScroll);
window.addEventListener('resize', headerScroll);

// headerDorpdownTarget.addEventListener('click', () => {
//   headerDorpdown.classList.toggle('is-open');
// });

// function headerDorpdownScrollHide() {
//   let decision = document.defaultView.getComputedStyle(headerDorpdown,null).display;
//   if(decision === 'none'){
//     header.classList.add('u-hidden')
//   } else {
//     header.classList.remove('u-hidden');
//   }
// }

headerHamburgerMenu.addEventListener('click', () => {
  html.classList.toggle('u-hidden');
  headerHamburgerMenu.classList.toggle('is-open');
  spMenu.classList.toggle('is-open');
  header.classList.toggle('is-open');
});

document.addEventListener('click', (e) => {
  if(!e.target.closest('.js-hamburger')) {
    html.classList.remove('u-hidden');
    headerHamburgerMenu.classList.remove('is-open');
    spMenu.classList.remove('is-open');
    header.classList.remove('is-open');
  }
  // if(!e.target.closest('.js-header-dorpdown-target')) {
  //   headerDorpdown.classList.remove('is-open');
  // }
});

// document.addEventListener('scroll', () => {
//   headerDorpdown.classList.remove('is-open');
// });