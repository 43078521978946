import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';

Swiper.use([Autoplay,Navigation,Pagination,EffectFade]);

const modal = document.querySelector('.p-top-modal');
const closeButton = document.querySelector('.p-top-modal__close-button');
const overlay = document.querySelector('.p-top-modal__overlay');
const modalTarget = [...document.querySelectorAll('.js-modal-target')];
const html = document.querySelector('html');
const body = document.querySelector('body');

modalTarget.forEach((target, index) => {
  target.addEventListener('click', () => {
    modal.classList.add('is-open');
    modalSwiper.slideTo(index - 2);
    html.classList.add('u-hidden');
    html.style.overflow = 'hidden';
    body.classList.add('u-hidden');
  })
})

closeButton.addEventListener('click', function(){
  modalClose();
});
overlay.addEventListener('click', function(){
  modalClose();
})
function modalClose () {
  modal.classList.remove('is-open');
  html.classList.remove('u-hidden');
  html.style.overflow = null;
  body.classList.remove('u-hidden');
}
// TOPページ modalスライダー
const modalSwiper = new Swiper('.p-top-modal__slide-wrap', {
  autoHeight: true,
  loop: true,
  slidesPerView: 1,
  pagination: {
    el: '.p-top-modal__swiper-pagination',
    clickable: true,
    type:"bullets",
  },
  navigation: {
    nextEl: '.p-top-modal__swiper-button-next',
    prevEl: '.p-top-modal__swiper-button-prev',
  },
});