import $ from 'jquery';
import 'jquery-match-height';

$(function() {
  $(window).on('load resize', function(){
    $('.js-matchHeight').matchHeight();
    $('.js-matchHeightText').matchHeight();
    $('.js-matchHeightHeading').matchHeight();
    $('.js-matchHeightGlobalHeading').matchHeight();
  })
});